import {
    Anchor, Box, Divider, rem,
    Stack, StackProps,
    Text,
    Title,
    MantineSize, MantineSpacing, MantineColor, MantineFontSize, Table
} from "@mantine/core";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import Link from "next/link";
import {StandardLonghandProperties} from "csstype";
import {HTMLAttributeAnchorTarget} from "react";

export type MarkdownPreviewProps = {
    content?: string;
    align?: StandardLonghandProperties["textAlign"];
    defaultFontSize?: MantineSize | MantineFontSize | number | undefined;
    defaultFontColor?: MantineColor;
    paragraphSpacing?: MantineSpacing;
    linkTarget?: HTMLAttributeAnchorTarget | undefined
} & StackProps;

export function MarkdownPreview(props: MarkdownPreviewProps) {
    const {
        content,
        align,
        paragraphSpacing,
        defaultFontSize,
        defaultFontColor,
        linkTarget,
        ...rest} = props;

    const paraSpacing = paragraphSpacing;
    let firstHeading = true;

    return (
        <Stack h={'100%'} gap={0} pr={'xs'} style={{overflowY: 'auto'}} {...rest}>
            <Markdown
                remarkPlugins={[remarkGfm]}
                components={{
                    h1({children}) {
                        const mt = firstHeading ? 0 : 12;
                        firstHeading = false;
                        return <Title order={2} mt={mt} mb={8}>{children}</Title>
                    },
                    h2({children}) {
                        const mt = firstHeading ? 0 : 12;
                        firstHeading = false;
                        return <Title order={3} mt={mt} mb={6}>{children}</Title>
                    },
                    h3({children}) {
                        const mt = firstHeading ? 0 : 12;
                        firstHeading = false;
                        return <Title order={4} mt={mt} mb={4}>{children}</Title>
                    },
                    h4({children}) {
                        const mt = firstHeading ? 0 : 12;
                        firstHeading = false;
                        return <Title order={5} mt={mt} mb={2}>{children}</Title>
                    },
                    pre({children}) {
                        return <Box py={rem(4)}>{children}</Box>
                    },
                    b({children}) {
                        return <Text span fw={600}>{children}</Text>
                    },
                    strong({children}) {
                        return <Text span fw={600}>{children}</Text>
                    },
                    code(props) {
                        return (
                            <code className={props.className} style={{fontWeight: 'bolder'}}>
                                {props.children}
                            </code>
                        );
                    },
                    p({children}) {
                        return <Text size={(defaultFontSize ?? 'md') as MantineSize | (string & {})}
                                     c={defaultFontColor}
                                     my={paraSpacing} style={{
                            textAlign: align,
                            textJustify: 'inter-character'
                        }}>{children}</Text>
                    },
                    // ul({children}) {
                    //     return <List ml={'md'}>{children}</List>
                    // },
                    // li({children}) {
                    //     return <List.Item fz={defaultFontSize ?? 'md'}>{children}</List.Item>
                    // },
                    a({children, href}) {
                        return (
                            <Anchor component={Link}
                                    // fz={defaultFontSize ?? 'inherit'}
                                    href={href!}
                                    scroll
                                    target={href!.startsWith('mailto:') ? '_blank' : href!.startsWith('#') ? '_self' : linkTarget ?? '_blank'}>
                                {children}
                            </Anchor>
                        );
                    },
                    hr({children}) {
                        return <Divider mt={rem(16)} mb={rem(16)}/>
                    },
                    table({children}) {
                        return <Table withTableBorder={true}
                                      // striped
                                      withRowBorders={true}
                                      withColumnBorders={true}
                                      horizontalSpacing={4}
                                      verticalSpacing={2}
                        >
                            {children}
                        </Table>
                    },
                    tr({children}) {
                        return <Table.Tr>{children}</Table.Tr>
                    },
                    th({children}) {
                        return <Table.Th>{children}</Table.Th>
                    },
                    td({children}) {
                        return <Table.Td>{children}</Table.Td>
                    },
                }}
            >
                {content}
            </Markdown>
        </Stack>
    )
}
