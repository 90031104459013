export type Optional<T> = T | undefined;

export type ObjectEntries<T> = {
    [K in keyof T]: [K, T[K]];
}[keyof T][];

export function objectEntries<T extends NonNullable<unknown>>(obj: T): ObjectEntries<T> {
    return Object.entries(obj) as ObjectEntries<T>;
}

export function isEmptyObject(obj: object): boolean {
    return Object.keys(obj).length === 0;
}

export function partitionBy<T, K>(items: T[], by: (v: T, index: number) => K, addToLastPartition?: (v: T) => boolean): {
    key: K,
    items: T[]
}[] {
    const partitions: { key: K, items: T[] }[] = [];
    let lastPartition: { key: K, items: T[] } | undefined = undefined;
    for (let i = 0; i < items.length; ++i) {
        const item = items[i];
        if (addToLastPartition != null && addToLastPartition(item)) {
            lastPartition!.items.push(item);
            continue;
        }
        const key = by(item, i);
        let partition = partitions[partitions.length - 1];
        if (partition == null || partition.key !== key) {
            partition = {key, items: [item]};
            partitions.push(partition);
        } else {
            partition.items.push(item);
        }
        lastPartition = partition;
    }
    return partitions;
}

export function groupBy<T>(items: T[], by: (v: T, index: number) => string | number): { [key: string | number]: T[] } {
    const groups: { [key: string | number]: T[] } = {};
    for (let i = 0; i < items.length; ++i) {
        const item = items[i];
        const key = by(item, i);
        let group = groups[key];
        if (group == null) {
            group = [];
            groups[key] = group;
        }
        group.push(item);
    }
    return groups;
}

export function tap<T>(tag: string, val: T): T {
    console.log(val);
    return val;
}

export const IS_PADDLE_IN_PRODUCTION = true;

export const IS_PAID_IN_PRODUCTION = true;
export const IS_ALPHA = true && !IS_PAID_IN_PRODUCTION;
export const IS_BETA = false;
export const IS_PROD = IS_PAID_IN_PRODUCTION;

export const DISABLE_YOUTUBE_VIDEOS = false;

export const FEEDBACK_DISCOUNT = 10;
export const DEFAULT_AFFILIATE_DISCOUNT = 10;

export const DEBUG_BORDERS = false;

export const MARKETING_URL = process.env.NEXT_PUBLIC_MARKETING_URL ??
    (IS_PROD ? 'https://www.freelimbs.com' :
        IS_BETA ? 'https://www.freelimbs.com' :
            IS_ALPHA ? 'https://www.freelimbs.com' :
                'http://localhost:8080');

export const APP_URL = process.env.NEXT_PUBLIC_APP_URL ??
    (IS_PROD ? 'https://app.freelimbs.com' :
        IS_BETA ? 'https://beta.freelimbs.com' :
            IS_ALPHA ? 'https://alpha.freelimbs.com' :
                'http://localhost:3000');

export const COOKIE_DOMAIN = process.env.NEXT_PUBLIC_COOKIE_DOMAIN ?? 'freelimbs.com';

export const SupabaseCookieOptions = {
    domain: COOKIE_DOMAIN,
    path: '/',
    sameSite: 'lax',
    // secure: true,
    // httpOnly: true,
    // maxAge: 60 * 60 * 24 * 365
} as {
    domain: string | undefined,
    path: string | undefined,
    sameSite: boolean | 'lax' | 'strict' | 'none' | undefined,
};

export const SIGN_OUT_COOKIES = [
    `sb-${process.env.NEXT_PUBLIC_SUPABASE_PROJECT}-auth-token`,
    `sb-${process.env.NEXT_PUBLIC_SUPABASE_PROJECT}-auth-token-code-verifier`,
];

export const TRIAL_DAYS = process.env.TRIAL_DAYS ? Number(process.env.TRIAL_DAYS) : 14;

export const SUPPORT_EMAIL = 'help@freelimbs.com';

export const APP_NOTICES_EMAIL = 'app-notices@freelimbs.com';
