export const DEBUG_AFFILIATES = false;

export const CustomAffiliateParam = 'pal';
export const ToltAffiliateParam = 'aff';

export const CustomAffiliateKey = 'fl-pal-id';
export const ToltAffiliateKey = 'fl-aff-id';

export function locallySet(key: string, value: string | null) {
    try {
        if (value == null) {
            sessionStorage.removeItem(key);
        } else {
            sessionStorage.setItem(key, value ?? '');
        }
        return;
    } catch (e) {
        // ignore - we're working on best effort here
    }
    try {
        if (value == null) {
            localStorage.removeItem(key);
        } else {
            localStorage.setItem(key, value ?? '');
        }
    } catch (e) {
        // ignore - we're working on best effort here
    }
}

export function locallyGet(key: string): string | null {
    let val: string | null;
    try {
        val = sessionStorage.getItem(key);
        if (val != null) {
            return val;
        }
    } catch (e) {
        // that might have failed to get written by locallySet, try localStorage
    }
    try {
        val = localStorage.getItem(key);
        if (val != null) {
            return val;
        }
    } catch (e) {
    }
    return null;
}

export function safeGetToltReferral() {
    if (typeof window === 'undefined') {
        return undefined;
    }
    let toltReferral: string | undefined;
    try {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
        const ref = (window as any).tolt_referral;
        if (ref != null) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            toltReferral = ref;
        }
    } catch (e) {
        // ignore
    }
    return toltReferral;
}
