"use client";

import {useRouter} from "next/navigation";
import {useEffect} from "react";
import {useLoggedInUser} from "@/components/common/hooks/useLoggedInUser";
import {IS_PAID_IN_PRODUCTION} from "@/common/utils";

export function RedirectToPricingAfterSignupWithWantedPlan() {
    const {user} = useLoggedInUser();
    const router = useRouter();

    useEffect(() => {
        if (!IS_PAID_IN_PRODUCTION || user == null) {
            return;
        }

        if (user?.user_metadata?.wantedPlan != null) {
            router.push('/pricing');
        }
    }, [router, user]);

    return null;
}
