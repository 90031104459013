"use client";

import {Grid, Stack} from "@mantine/core";
import {Hero} from "@/components/hero/Hero";
import {HookVideo} from "@/components/hero/HookVideo";
import {RealTimeHero} from "@/components/hero/RealTimeHero";

export function HeroSection() {
    return (
        <Grid gutter={{ base: 16, md: 24, lg: 32, xl: 40 }}>
            <Grid.Col span={{base: 12, md: 12, lg: 6, xl: 5}} visibleFrom={'lg'}>
                <Stack justify={'center'} align={'stretch'} h={'100%'}>
                    <Hero/>
                    <RealTimeHero />
                </Stack>
            </Grid.Col>
            <Grid.Col span={{base: 12, md: 12, lg: 12}} hiddenFrom={'lg'}>
                <Stack justify={'center'} align={'stretch'} h={'100%'}>
                    <Hero/>
                </Stack>
            </Grid.Col>
            <Grid.Col span={{base: 12, md: 12, lg: 6, xl: 7}}>
                <HookVideo ytId={'5PHG_taijEk?si=C_d5B2y77MM80ptB'}
                           title={'FreeLimbs | Master drum set coordination'}
                           eager
                />
            </Grid.Col>
            <Grid.Col span={{base: 12, md: 12, lg: 12}} hiddenFrom={'lg'}>
                <Stack justify={'center'} align={'stretch'} h={'100%'}>
                    <RealTimeHero />
                </Stack>
            </Grid.Col>
        </Grid>
    )
}
