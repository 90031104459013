"use client";

import {Feature} from "@/components/features/Feature";
import {Grid, Group, rgba, Stack, Text, useMantineColorScheme, useMantineTheme} from "@mantine/core";
import {useSlightlyDimmedColor} from "@/components/common/hooks/useColors";
import {useMemo, useRef} from "react";
import {Span} from "@/components/common/Span";
import {Carousel} from "@mantine/carousel";
import Autoplay from 'embla-carousel-autoplay';
import {
    IconCircleCaretLeft,
    IconCircleCaretRight
} from "@tabler/icons-react";

export type FeatureItemProps = {
    feature: Feature;
    odd: boolean;
};

export function FeatureItem({feature, odd}: FeatureItemProps) {
    const theme = useMantineTheme();
    const {colorScheme: scheme} = useMantineColorScheme();
    const dimmed = useSlightlyDimmedColor();

    const titleSize = 'h2'; // 28;
    const subTitleSize = 'h6'; // 16
    const textSize = 'lg'; // 20;
    const mobileTextSize = 'md'; // 20;

    const icon = useMemo(() => {
        if (scheme === 'dark' || !feature.iconLight) {
            return feature.icon;
        }
        return feature.iconLight;
    }, [feature.icon, feature.iconLight, scheme]);

    const oddColorIndex = scheme === 'dark' ? 1 : 4;

    const autoplay = useRef(Autoplay({delay: feature.showCaseDelay ?? 5000}));

    const slidesCol = useMemo(() => {
        if (feature.slides.length === 0) {
            return undefined;
        }
        return (
            <Grid.Col span={{base: 12, sm: 6, md: 7, lg: 8}}>
                <Carousel
                    // classNames={classes}
                          style={{flex: 1}}
                          height={'100%'}
                          nextControlIcon={<IconCircleCaretRight strokeWidth={1.5} size={32}/>}
                          previousControlIcon={<IconCircleCaretLeft strokeWidth={1.5} size={32}/>}
                    // withIndicators
                          withControls={feature.slides.length > 1}
                          slideGap={'xs'}
                          controlsOffset={'xs'}
                          align={'center'}
                          loop
                    // slideSize={{base: '100%', sm: '50%', lg: '33.333%'}}
                          plugins={[autoplay.current]}
                          onMouseEnter={autoplay.current.stop}
                          onMouseLeave={autoplay.current.reset}
                >
                    {feature.slides.map((slide, index) => (
                        <Carousel.Slide key={index}>
                            {slide}
                        </Carousel.Slide>
                    ))}
                </Carousel>
            </Grid.Col>
        );
    }, [feature.slides]);

    const textCol = useMemo(() => {
        const span = slidesCol != null ? {base: 12, sm: 6, md: 5, lg: 4} : {base: 12};
        return (
            <Grid.Col span={span}>
                <Stack gap={'xs'}>
                    <Group align={'center'} wrap={'nowrap'}>
                        {icon}
                        {/*{feature.image}*/}
                        <Stack gap={6}>
                            <Span ff={'var(--mantine-font-family-headings)'} fw={'bold'} fz={titleSize}
                                  lh={1.2}>{feature.title}</Span>
                            <Span fz={subTitleSize} c={dimmed} fw={500}
                                  lh={1.2}>{feature.subTitle}</Span>
                        </Stack>
                    </Group>
                    <Stack gap={0} visibleFrom={'md'}>
                        {feature.description.map((part, index) => (
                            <Text py={'xs'} key={index} fz={textSize} c={dimmed}>{part}</Text>
                        ))}
                    </Stack>
                    <Stack gap={0} hiddenFrom={'md'}>
                        {feature.description.map((part, index) => (
                            <Text py={'xs'} key={index} fz={mobileTextSize} c={dimmed}>{part}</Text>
                        ))}
                    </Stack>
                </Stack>
            </Grid.Col>
        );
    }, [dimmed, feature.description, feature.subTitle, feature.title, icon, slidesCol]);

    return (
        <Stack style={{borderRadius: 4}}
               py={{base: 16, md: 24 }}
               px={{base: 12, sm: 20, md: 24}} gap={0} h={'100%'}
               bg={!odd ?
                   rgba(theme.colors[theme.primaryColor][oddColorIndex], 0.075) :
                   undefined // rgba(theme.colors[theme.primaryColor][evenColorIndex], 0.075)
               }
        >
            {slidesCol == null && (
                <Grid gutter={{base: 16, md: 24}}>
                    {textCol}
                </Grid>
            )}
            {slidesCol != null && (
                <>
                    <Grid gutter={{base: 16, md: 24}} hiddenFrom={'sm'}>
                        {textCol}
                        {slidesCol}
                    </Grid>
                    <Grid gutter={{base: 16, md: 24}} visibleFrom={'sm'}>
                        {odd ? textCol : slidesCol}
                        {odd ? slidesCol : textCol}
                    </Grid>
                </>
            )}
        </Stack>
    )
}
