import {ReactNode} from "react";
import {Anchor} from "@mantine/core";
import {Span} from "@/components/common/Span";

export function parseTextWithSimpleLinks(bio: string): ReactNode {
    const children: ReactNode[] = [];

    let start = 0;

    for (;;) {
        const linkStart = bio.indexOf('[', start);
        if (linkStart === -1) {
            children.push(bio.substring(start));
            break;
        }
        const linkEnd = bio.indexOf('](', linkStart);
        if (linkEnd === -1) {
            children.push(bio.substring(start));
            break;
        }
        const linkText = bio.substring(linkStart + 1, linkEnd);
        const urlStart = linkEnd + 2;
        const urlEnd = bio.indexOf(')', urlStart);
        if (urlEnd === -1) {
            children.push(bio.substring(start));
            break;
        }
        const url = bio.substring(urlStart, urlEnd);
        children.push(bio.substring(start, linkStart));
        children.push(
            <Anchor href={url} target={'_blank'}>{linkText}</Anchor>
        );
        start = urlEnd + 1;
    }

    return children.map((child, i) => <Span key={i}>{child}</Span>);
}
