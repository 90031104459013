"use client";

import {Grid} from "@mantine/core";
import {TestimonialWidget} from "@/components/testimonials/TestimonialWidget";
import {Testimonial} from "@/components/testimonials/testimonial";

export function Testimonials({testimonials}: {testimonials: Testimonial[]}) {
    const lg = testimonials.length >= 3 ? 4 : 6;
    const xl = testimonials.length >= 4 ? 3 : testimonials.length >= 3 ? 4 : 6;
    return (
        <Grid gutter={{ base: 12, sm: 16, md: 24, lg: 32 }}>
            {testimonials.map((testimonial, i) => (
                <Grid.Col span={{base: 12, md: 6, lg, xl}} key={i}>
                    <TestimonialWidget testimonial={testimonial} />
                </Grid.Col>
            ))}
        </Grid>
    )
}