import {getPrimaryColor} from "@/components/common/get-primary-button-color";
import {useMemo} from "react";
import {useMantineColorScheme, useMantineTheme} from "@mantine/core";

export function usePrimaryColor() {
    const theme = useMantineTheme();
    const {colorScheme: scheme} = useMantineColorScheme();

    return useMemo(() => getPrimaryColor(theme, scheme), [theme, scheme]);
}

export function useAccentColor() {
    const theme = useMantineTheme();
    const {colorScheme: scheme} = useMantineColorScheme();

    return useMemo(() =>
        scheme === 'light' ? theme.colors.dark[3] : theme.colors.gray[4], [theme, scheme]);
}

export function useHighContrastColor() {
    const theme = useMantineTheme();
    const {colorScheme: scheme} = useMantineColorScheme();

    return useMemo(() =>
        scheme === 'light' ? theme.colors.gray[9] : theme.colors.gray[4], [theme, scheme]);
}

export function useSlightlyDimmedColor() {
    const theme = useMantineTheme();
    const {colorScheme: scheme} = useMantineColorScheme();

    return useMemo(() =>
        scheme === 'light' ? theme.colors.dark[5] : theme.colors.dark[1], [theme, scheme]);
}

export function useDimmedColor() {
    const theme = useMantineTheme();
    const {colorScheme: scheme} = useMantineColorScheme();

    return useMemo(() =>
        scheme === 'light' ? theme.colors.dark[2] : theme.colors.gray[6], [theme, scheme]);
}

export function useHighlightColor() {
    const theme = useMantineTheme();
    const {colorScheme: scheme} = useMantineColorScheme();

    return useMemo(() => {
        const colors = theme.colors.rougeRed;
        const color = colors[scheme === 'dark' ? 4 : 5];
        return color;
    }, [scheme, theme.colors.rougeRed]);
}

export function useDangerColor() {
    const theme = useMantineTheme();
    const {colorScheme: scheme} = useMantineColorScheme();

    return useMemo(() => {
        return theme.colors.rougeRed[scheme === 'dark' ? 4 : 6];
    }, [scheme, theme.colors.rougeRed])
}

export function useValidationColor() {
    const theme = useMantineTheme();
    const {colorScheme: scheme} = useMantineColorScheme();

    return useMemo(() => {
        return theme.colors.gray[scheme === 'dark' ? 8 : 2];
    }, [scheme, theme.colors.gray])
}

export function useSlightlyColoredText() {
    const theme = useMantineTheme();
    const {colorScheme: scheme} = useMantineColorScheme();

    return useMemo(() => {
        return theme.colors[theme.primaryColor][scheme === 'dark' ? 1 : 9];
    }, [scheme, theme.primaryColor, theme.colors])
}
