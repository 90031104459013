import {ServerMarkdownContent} from "@/components/common/ServerMarkdownContent";
import {ClientMarkdownContent} from "@/components/common/ClientMarkdownContent";
import {MarkdownPreviewProps} from "@/components/common/MarkdownPreview";

export function MarkdownContent(props: MarkdownPreviewProps) {
    if (typeof window === 'undefined') {
        return <ServerMarkdownContent {...props} />;
    } else {
        return <ClientMarkdownContent {...props} />;
    }
}
