"use client";

import {AspectRatio, Skeleton} from "@mantine/core";
import {useCallback, useEffect, useMemo, useState} from "react";
import {DISABLE_YOUTUBE_VIDEOS} from "@/common/utils";

export type HookVideoProps = {
    ytId: string;
    title: string;
    width?: string | undefined;
    height?: string | undefined;
    noCookie?: boolean | undefined;
    autoPlay?: boolean | undefined;
    start?: number | undefined;
    eager?: boolean | undefined;
}

export function HookVideo(props: HookVideoProps) {
    const [loadingVideo, setLoadingVideo] = useState(true);
    useEffect(() => {
        setTimeout(() => {
            setLoadingVideo(false);
        }, 0);
    }, []);

    const [loaded, setLoaded] = useState(false);
    const onLoaded = useCallback(() => {
        // console.log('Loaded.');
        setLoaded(true);
    }, []);

    const videoLink = useMemo(() => {
        const noCookie = props.noCookie == null || props.noCookie;
        return loadingVideo ?
            '' :
            DISABLE_YOUTUBE_VIDEOS ?
                'about:blank' :
                `https://www.youtube${noCookie ? '-nocookie' : ''}.com/embed/${props.ytId}&controls=1&autoplay=${props.autoPlay ? 1 : 0}${props.start != null ? `&start=${props.start}` : ''}`;
    }, [loadingVideo, props.autoPlay, props.noCookie, props.start, props.ytId]);

    return (
        <Skeleton visible={loadingVideo || !loaded}>
            <AspectRatio ratio={16 / 9}>
                <iframe width={'100%'}
                        height={'100%'}
                        hidden={loadingVideo}
                        style={{border: 'none'}}
                        src={videoLink}
                        title={props.title}
                        allow="accelerometer; encrypted-media; gyroscope; picture-in-picture;"
                        allowFullScreen
                        loading={props.eager ? 'eager' : 'lazy'}
                        onLoad={onLoaded}
                ></iframe>
                <div style={{
                    display: loadingVideo ? 'flex' : 'none',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flex: 1,
                    minWidth: '100%',
                    minHeight: '100%'
                }}/>
            </AspectRatio>
        </Skeleton>
    );
}
