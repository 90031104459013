import {MarkdownPreview, MarkdownPreviewProps} from "@/components/common/MarkdownPreview";
import {Box} from "@mantine/core";

export function ServerMarkdownContent(props: MarkdownPreviewProps) {
    return (
        <Box maw={props.maw ?? 1024} style={{ overflowY: 'auto' }}>
            <MarkdownPreview {...props} />
        </Box>
    );
}
