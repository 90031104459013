"use client";

import {useSearchParams} from "next/navigation";
import {store} from "@/app/lib/data/appState";
import {useCallback, useEffect, useState} from "react";
import {useAtomValue, useSetAtom} from "jotai/index";
import {
    CustomAffiliateKey,
    CustomAffiliateParam, DEBUG_AFFILIATES,
    locallySet, safeGetToltReferral,
    ToltAffiliateKey,
    ToltAffiliateParam
} from "@/components/common/affiliate-utils";
import {useInterval} from "@mantine/hooks";
import {useSafePathName} from "@/components/common/hooks/useSafePathName";

const DEBUG = DEBUG_AFFILIATES;

const TOLT_AFFILIATE_CHECK_INTERVAL = 500;
const MAX_SECONDS_TO_RESOLVE_TOLT_AFFILIATE = 10;
const MAX_TOLT_CHECKS = MAX_SECONDS_TO_RESOLVE_TOLT_AFFILIATE * 1000 / TOLT_AFFILIATE_CHECK_INTERVAL;

export default function TrackAffiliate() {
    // DEBUG && console.log('TrackAffiliate loaded');

    const pathName = useSafePathName();
    const search = useSearchParams();
    const setState = useSetAtom(store.state);
    const customAffiliateId = useAtomValue(store.customAffiliateId);
    const toltAffiliateId = useAtomValue(store.toltAffiliateId);

    const [toltCheckCounter, setToltCheckCounter] = useState(0);
    const checkToltReferral = useCallback(() => {
        setToltCheckCounter(state => state + 1);
    }, []);
    const { start, stop } = useInterval(checkToltReferral, 500, { autoInvoke: false });

    useEffect(() => {
        if (customAffiliateId == null && toltAffiliateId == null) {
            if (toltCheckCounter === MAX_TOLT_CHECKS) {
                return;
            }
            if (DEBUG) {
                console.log('TrackAffiliate - no affiliates, starting Tolt check timer');
            }
            start();
        } else {
            if (DEBUG) {
                console.log('TrackAffiliate - affiliate resolved, stopping Tolt check timer', customAffiliateId, toltAffiliateId);
            }
            stop();
        }
        return () => {
            if (DEBUG) {
                console.log('TrackAffiliate - Stopping Tolt check timer');
            }
            stop();
        }
    }, [customAffiliateId, toltAffiliateId, start, stop, toltCheckCounter]);

    useEffect(() => {
        if (DEBUG) {
            console.log(`[${toltCheckCounter}] Tracking affiliate`, pathName, search.toString());
        }

        let toltReferal: string | undefined;

        if (search.has(CustomAffiliateParam)) {
            const affiliateId = search.get(CustomAffiliateParam);
            setState(state => {
                state.customAffiliateId = affiliateId;
            });
            locallySet(CustomAffiliateKey, affiliateId);
            DEBUG && console.log('TrackAffiliate - custom affiliate resolved', CustomAffiliateKey, affiliateId, 'stopping Tolt timer');
            // stop Tolt tracking
            stop();
        } else if ((toltCheckCounter > 0) && (toltReferal = safeGetToltReferral()) != null) {
            const affiliateId = toltReferal;
            setState(state => {
                state.toltAffiliateId = affiliateId;
            });
            locallySet(ToltAffiliateKey, affiliateId);
            DEBUG && console.log('TrackAffiliate - Tolt affiliate resolved', ToltAffiliateParam, affiliateId, 'stopping Tolt timer');
            // stop Tolt tracking
            stop();
        } else {
            DEBUG && console.log('TrackAffiliate - no affiliate resolved');
            if (toltCheckCounter === MAX_TOLT_CHECKS) {
                DEBUG && console.log('TrackAffiliate - Tolt affiliate check limit reached, stopping Tolt timer');
                stop();
            }
        }
    }, [pathName, search, setState, customAffiliateId, toltAffiliateId, toltCheckCounter, stop]);

    return null;
}
