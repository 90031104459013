"use client";

import {CoolCard} from "@/components/common/CoolCard";
import {Features} from "@/components/features/Features";
import {useFeatureList} from "@/components/features/feature-list";

export function FeaturesSection() {
    const features = useFeatureList();
    return (
        <CoolCard>
            <Features features={features} />
        </CoolCard>
    )
}
