"use client";

import Script from "next/script";
import {useCallback} from "react";
import * as Sentry from "@sentry/nextjs";

export default function ToltScript() {
    const onToltError = useCallback((e: any) => {
        Sentry.captureException(e);
    }, []);

    return (
        <Script src="https://cdn.tolt.io/tolt.js"
                strategy={'lazyOnload'}
                onError={onToltError}
                data-tolt={process.env.NEXT_PUBLIC_TOLT_ID!}
        ></Script>
    );
}