"use client";

import {ParaProps} from "@/components/common/Para";
import {Span} from "@/components/common/Span";
import {useHighContrastColor} from "@/components/common/hooks/useColors";

export function Emp(props: ParaProps) {
    const highContrast = useHighContrastColor();
    const { children, ...rest } = props;
    return (<Span c={highContrast} fw={600} {...rest}>{children}</Span>);
}
