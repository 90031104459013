"use client";

import {useMemo} from "react";
import {Feature} from "@/components/features/Feature";
import {Span} from "@/components/common/Span";
import {
    IconCircle,
    IconDeviceMobileHeart, IconEar, IconFlame,
    IconGrid4x4, IconHeartHandshake, IconMoodWink,
    IconPlaylist, IconRocket
} from "@tabler/icons-react";
import {Anchor, AspectRatio, useMantineColorScheme, useMantineTheme} from "@mantine/core";
import Image from "next/image";
import {Emp} from "@/components/common/Emp";
import {HookVideo} from "@/components/hero/HookVideo";
import {Accent} from "@/components/common/Accent";

// add accents to the text

export function useFeatureList() {
    const theme = useMantineTheme();
    const {colorScheme: scheme} = useMantineColorScheme();
    const color = theme.colors[theme.primaryColor][scheme === 'light' ? 6 : 7];
    const stroke = 1;
    const result = useMemo(() => {

        let key = 0;
        const iconSize = 68; // 80 for images

        const features: Feature[] = [
            {
                title: <Span>Simple</Span>,
                subTitle: 'Music theory not required',
                description: [
                    <Span key={++key}><Emp>Everyone can learn</Emp> drum coordination. You don&apos;t need to sight-read
                        drum notation or learn music theory.
                    </Span>,
                    <Span key={++key}>
                        <Accent>Ringo Starr (The Beatles), John Bonham (Led Zeppelin), Chad Smith (RHCP), and Buddy Rich</Accent> <Emp>did not</Emp>.
                    </Span>,
                    <Span key={++key}>FreeLimbs makes coordination so simple, it <Emp>can be learned by kids or
                        beginners</Emp> without any background in music.
                    </Span>,
                ],
                icon: <IconMoodWink strokeWidth={stroke} size={iconSize} color={color}/>,
                // icon: <IconCircle strokeWidth={stroke} size={iconSize} color={color}/>,
                // slidesHeight: 436,
                slides: [
                    <AspectRatio ratio={16 / 9} key={++key}>
                        <div style={{width: '100%', height: '100%', maxHeight: 480, position: 'relative'}}>
                            <HookVideo key={++key} ytId={'iAUDQnthaxs?si=YpDdG8pFu88_f7Fy'}
                                       title={'FreeLimbs | Drum coordination made simple'}
                            />
                        </div>
                    </AspectRatio>
                ]
            },
            {
                title: <Span>Complete</Span>,
                subTitle: 'A rich library of musical genres & styles',
                description: [
                    <Span key={++key}>
                        From the steady backbeat of Rock to the intricate patterns of Jazz and Latin rhythms, we have
                        you covered.
                    </Span>,
                    <Span key={++key}>
                        Enjoy <Emp>thousands of exercises</Emp> across a <Emp>variety of genres and styles</Emp>,
                        including Rock,
                        {' '}Funk, Pop, R&B, Gospel, Metal, Hip-Hop, Blues, Ballads, Jazz, Afro-Cuban, Latin, and
                        Fusion.
                    </Span>,
                    <Span key={++key}>
                        Fancy <Emp>Gospel chops?</Emp> Who doesn&apos;t? We have them too.
                    </Span>,
                ],
                icon: <IconCircle strokeWidth={stroke} size={iconSize} color={color} fill={color} floodColor={color}/>,
                slides: [
                    <AspectRatio ratio={806 / 480} key={++key}>
                        <div style={{width: '100%', height: '100%', maxHeight: 480, position: 'relative'}}>
                            <Image src={`/images/features/complete/straight-swung.png`}
                                   key={++key}
                                   alt={'Straight & swung backbeat'}
                                   layout='fill'
                                   objectFit='contain'
                                   sizes="(max-width: 768px) 100vw, (max-width: 990px) 50vw, 66vw"
                            /></div>
                    </AspectRatio>,
                    <AspectRatio ratio={806 / 480} key={++key}>
                        <div style={{width: '100%', height: '100%', maxHeight: 480, position: 'relative'}}>
                            <Image src={`/images/features/complete/latin-jazz.png`}
                                   key={++key}
                                   alt={'Afro-Cuban/Latin & Jazz'}
                                   layout='fill'
                                   objectFit='contain'
                                   sizes="(max-width: 768px) 100vw, (max-width: 990px) 50vw, 66vw"
                            /></div>
                    </AspectRatio>,
                    <AspectRatio ratio={915 / 480} key={++key}>
                        <div style={{width: '100%', height: '100%', maxHeight: 480, position: 'relative'}}>
                            <Image src={`/images/features/complete/linear.png`}
                                   key={++key}
                                   alt={'Linear exercises'}
                                   layout='fill'
                                   objectFit='contain'
                                   sizes="(max-width: 768px) 100vw, (max-width: 990px) 50vw, 66vw"
                            /></div>
                    </AspectRatio>,
                ]
            },
            {
                title: 'Easy to see',
                subTitle: 'Visual learning for every exercise',
                description: [
                    <Span key={++key}>See the beat and how your hands and feet align with <Emp>crystal-clear visual
                        cues</Emp>.
                    </Span>,
                    <Span key={++key}>No more guesswork — know exactly when and where to strike. Our grid guides you
                        through
                        each beat and shows you what&apos;s coming next, so you can prepare.
                    </Span>,
                    <Span key={++key}>
                        Perfect for those accustomed to drum tabs, or coming from Guitar Hero/Rock Band.
                    </Span>,
                ],
                icon: <IconGrid4x4 strokeWidth={stroke} size={iconSize} color={color}/>,
                slides: [
                    <AspectRatio ratio={16 / 9} key={++key}>
                        <div style={{width: '100%', height: '100%', maxHeight: 480, position: 'relative'}}>
                            <HookVideo key={++key} ytId={'3_3X701bepY?si=k0RRGI2eqVhrdju2'}
                                       title={'FreeLimbs | Drum coordination made clear'}
                            />
                        </div>
                    </AspectRatio>
                ]
            },
            {
                title: 'Easy to hear',
                subTitle: 'Audible exercises in a variety of tempos',
                description: [
                    <Span key={++key}>Feel the rhythm before you hit the drum.
                        {' '}Never wonder whether you got the exercise right — you&apos;ll <Emp>hear it before you play
                            it</Emp>.
                    </Span>,
                    <Span key={++key}>
                        At different tempos. With, and without a metronome. With, or without music.
                    </Span>,
                    <Span key={++key}>
                        <Emp>Swing will never be a mystery</Emp> again. You&apos;ll see, hear, feel and master it, in four
                        different ways.
                    </Span>
                ],
                icon: <IconEar strokeWidth={stroke} size={iconSize} color={color}/>,
                slides: [
                    <AspectRatio ratio={16 / 9} key={++key}>
                        <div style={{width: '100%', height: '100%', maxHeight: 480, position: 'relative'}}>
                            <HookVideo key={++key} ytId={'JqBFSmTzsYc?si=yuPRnJGWta0wyNzV'}
                                       title={'FreeLimbs | Drum coordination made audible'}
                            />
                        </div>
                    </AspectRatio>
                ]
            },
            {
                title: <Span>Enjoyable</Span>,
                subTitle: 'Hundreds of drumless tracks for engaging practice',
                description: [
                    <Span key={++key}>Make every practice session memorable by jamming to
                        {' '}<Emp>300+ superb drumless music tracks</Emp>, available in a variety of genres and tempos.
                    </Span>,
                    <Span key={++key}>
                        Feel the sensation of leading a band, on your <Emp>no-stress private</Emp> stage.
                    </Span>,
                    <Span key={++key}>
                        From Jazz to Funk, Rock to Samba, <Anchor fz={'inherit'}
                                                                  target={'_blank'}
                                                                  rel={'noopener noreferrer'}
                                                                  href={'https://www.imdb.com/name/nm4348879/bio/'}>
                        Andre Forbes</Anchor> and his treasured by all drummers <Emp>original compositions</Emp> await.
                    </Span>,
                ],
                icon: <IconPlaylist strokeWidth={stroke} size={iconSize} color={color}/>,
                slides: [
                    <AspectRatio ratio={16 / 9} key={++key}>
                        <div style={{width: '100%', height: '100%', maxHeight: 480, position: 'relative'}}>
                            <HookVideo key={++key} ytId={'8CFBOwTXPJM?si=C4XmUlmblxD2IIkc'}
                                       title={'FreeLimbs | Drum coordination made delightful'}
                            />
                        </div>
                    </AspectRatio>,
                    <AspectRatio ratio={16 / 9} key={++key}>
                        <div style={{width: '100%', height: '100%', maxHeight: 480, position: 'relative'}}>
                            <HookVideo key={++key} ytId={'ir8K2Z4N8Po?si=Gk-L9W1CwFKXzNTr'}
                                       title={`George "Spanky" McCurdy - "Fusion Express" by Andre Forbes`}
                            />
                        </div>
                    </AspectRatio>,
                    <AspectRatio ratio={16 / 9} key={++key}>
                        <div style={{width: '100%', height: '100%', maxHeight: 480, position: 'relative'}}>
                            <HookVideo key={++key} ytId={'cJUJ9YbIux0?si=iBoR3krwaWwX2RgU'} start={3}
                                       title={`J-rod Sullivan - "Funk Groove" by Andre Forbes`}
                            />
                        </div>
                    </AspectRatio>,
                    <AspectRatio ratio={16 / 9} key={++key}>
                        <div style={{width: '100%', height: '100%', maxHeight: 480, position: 'relative'}}>
                            <HookVideo key={++key} ytId={'lfTdUlSaeqE?si=_uLSsmpSiqmD7TRq'}
                                       title={`Marcus Thomas - "Yeah Yeah Yeah" by Andre Forbes`}
                            />
                        </div>
                    </AspectRatio>
                    /*
                    https://www.youtube.com/watch?v=ecrFxVsMeIo&list=PLrO_NhbSYrHiBJ5e8zX5hhoswEcxGAly4&index=78
                    https://www.youtube.com/watch?v=jCz08uuVm9U&list=PLrO_NhbSYrHiBJ5e8zX5hhoswEcxGAly4&index=77
                        (contact ^)
                    https://www.youtube.com/watch?v=TTs72_1ntmY&list=PLrO_NhbSYrHiBJ5e8zX5hhoswEcxGAly4&index=83
                    https://www.youtube.com/watch?v=upVMYXnd-4Y&list=PLrO_NhbSYrHiBJ5e8zX5hhoswEcxGAly4&index=90
                        (contact ^)
                    https://www.youtube.com/watch?v=bSUmQTKnfQ0&list=PLrO_NhbSYrHiBJ5e8zX5hhoswEcxGAly4&index=91
                    https://www.youtube.com/watch?v=fclDVvGd9-o&list=PLrO_NhbSYrHiBJ5e8zX5hhoswEcxGAly4&index=97
                    https://www.youtube.com/watch?v=ewqqFTk3zl8&list=PLrO_NhbSYrHiBJ5e8zX5hhoswEcxGAly4&index=100
                        (contact ^ - because he enjoyed Spanky's video on Space Fusion)
                    https://www.youtube.com/watch?v=77yu78jKylE&list=PLrO_NhbSYrHiBJ5e8zX5hhoswEcxGAly4&index=102
                    https://www.youtube.com/watch?v=o5yJGLCbwAU&list=PLrO_NhbSYrHiBJ5e8zX5hhoswEcxGAly4&index=105
                    https://www.youtube.com/watch?v=67MjBeyEEQE&list=PLrO_NhbSYrHiBJ5e8zX5hhoswEcxGAly4&index=144

                    https://www.youtube.com/watch?v=_tUSmsf6Oss&list=PLrO_NhbSYrHiBJ5e8zX5hhoswEcxGAly4&index=174
                        (kalonica)
                    https://www.youtube.com/watch?v=lkd-LEWOlmk&list=PLrO_NhbSYrHiBJ5e8zX5hhoswEcxGAly4&index=191
                        (zack at 1:47)
                    https://www.youtube.com/watch?v=icZJB17oyzA&list=PLrO_NhbSYrHiBJ5e8zX5hhoswEcxGAly4&index=192
                    https://www.youtube.com/watch?v=g9bL2UVi_QU&list=PLrO_NhbSYrHiBJ5e8zX5hhoswEcxGAly4&index=208
                        (maybe^)
                    https://www.youtube.com/watch?v=SaNh5668Sd0&list=PLrO_NhbSYrHiBJ5e8zX5hhoswEcxGAly4&index=224&t=25s
                        (maybe^)
                    https://www.youtube.com/watch?v=NzclL5YwxPA&list=PLrO_NhbSYrHiBJ5e8zX5hhoswEcxGAly4&index=236
                    https://www.youtube.com/watch?v=stK0LRIlN8g&list=PLrO_NhbSYrHiBJ5e8zX5hhoswEcxGAly4&index=237
                    https://www.youtube.com/watch?v=kH7iUDivQ8E&list=PLrO_NhbSYrHiBJ5e8zX5hhoswEcxGAly4&index=240
                    https://www.youtube.com/watch?v=v-y2T2iANH0&list=PLrO_NhbSYrHiBJ5e8zX5hhoswEcxGAly4&index=243
                    https://www.youtube.com/watch?v=9c96Q09oOYk&list=PLrO_NhbSYrHiBJ5e8zX5hhoswEcxGAly4&index=273&t=14s
                    https://www.youtube.com/watch?v=cmgJ7mGuJQk&list=PLrO_NhbSYrHiBJ5e8zX5hhoswEcxGAly4&index=291&t=29s
                    https://www.youtube.com/watch?v=aMAPrgViANs&list=PLrO_NhbSYrHiBJ5e8zX5hhoswEcxGAly4&index=295
                    https://www.youtube.com/watch?v=ikZAPIoXiAo&list=PLrO_NhbSYrHiBJ5e8zX5hhoswEcxGAly4&index=8&t=31s
                        (Marcus)
                    https://www.youtube.com/watch?v=cJUJ9YbIux0&list=PLrO_NhbSYrHiBJ5e8zX5hhoswEcxGAly4&index=305
                        (J-Rod)
                    https://www.youtube.com/watch?v=sxWLoIYCJnc&list=PLrO_NhbSYrHiBJ5e8zX5hhoswEcxGAly4&index=324
                    https://www.youtube.com/watch?v=XZHEeoxzat4&list=PLrO_NhbSYrHiBJ5e8zX5hhoswEcxGAly4&index=388
                    https://www.youtube.com/watch?v=YaimRdGnkfA&list=PLrO_NhbSYrHiBJ5e8zX5hhoswEcxGAly4&index=433
                        (J-Rod) // cJUJ9YbIux0?si=enaK2i_YZKgcdP-G
                    https://www.youtube.com/watch?v=9FjKDhDZ8Jc&list=PLrO_NhbSYrHiBJ5e8zX5hhoswEcxGAly4&index=453
                        (contact?)
                    https://www.youtube.com/watch?v=_7vF3GJMVWk&list=PLrO_NhbSYrHiBJ5e8zX5hhoswEcxGAly4&index=516
                        (definitely)
                    https://www.youtube.com/watch?v=AzTz61aS1g8&t=0s

                     */
                ]
            },
            {
                title: 'Challenging',
                subTitle: 'Exercises for all skill levels',
                description: [
                    <Span key={++key}>Thousands of exercise variations <Emp>will keep even the most advanced
                        drummers engaged</Emp>.</Span>,
                    <Span key={++key}>Move the exercise around the kit, add <Emp>hi-hat stomps or sweeps</Emp>, spice it
                        up with <Emp>ghost notes</Emp> on the snare drum,
                        {' '}<Emp>swing it</Emp> in four different ways.</Span>,
                    <Span key={++key}>Create <Emp>millions of linear grooves or fills</Emp>, with unique
                        dynamics &amp; orchestrations.</Span>,
                ],
                // icon: <IconClock strokeWidth={stroke} size={iconSize} color={color}/>,
                icon: <IconFlame strokeWidth={stroke} size={iconSize} color={color}/>,
                slides: [
                    <AspectRatio ratio={16 / 9} key={++key}>
                        <div style={{width: '100%', height: '100%', maxHeight: 480, position: 'relative'}}>
                            <HookVideo key={++key} ytId={'-8W4YebP2HI?si=Wfi0b6GBuTNFK56L'}
                                       title={'FreeLimbs | Drum coordination made engaging'}
                            />
                        </div>
                    </AspectRatio>,
                    <AspectRatio ratio={16 / 9} key={++key}>
                        <div style={{width: '100%', height: '100%', maxHeight: 480, position: 'relative'}}>
                            <HookVideo key={++key} ytId={'f0pP7H3j5mg?si=cjrJwsjhogKWauG1'}
                                       title={'FreeLimbs | Drum coordination made challenging'}
                            />
                        </div>
                    </AspectRatio>
                    // http://localhost:3000/linear/chops?nc=12&al=1&bb=2&bk=1&bs=1&c1=1&li=7&mkp=50&nl=1&rs=20240903112821220&t=132&ai=12.5.10.14.7&d=1&da=1&di=2&mgp=50&o=1&oi=4&xo=1&xon=2
                ]
            },
            {
                title: 'Accelerating',
                subTitle: 'Real-time feedback for your e-kit or pads',
                description: [
                    <Span key={++key}>
                        {' '}With <Emp>real-time</Emp> feedback on your practice, <Emp>while you&apos;re
                        playing</Emp>,
                        {' '}you <Emp>will</Emp> be grooving, <Emp>sooner</Emp> than you thought.
                    </Span>,
                    <Span key={++key}>Know whether you&apos;re <Emp>in the pocket</Emp>, <Emp>grooving</Emp>,
                        {' '}<Emp>rushing</Emp>, or <Emp>dragging</Emp>. Show it off to your friends, or keep it to
                        yourself and shock them.
                    </Span>,
                    <Span key={++key}>After every exercise you practice to, you&apos;ll see your
                        {' '}<Emp>overall, macro-, and micro-timing</Emp>, <Emp>dynamics</Emp> and
                        {' '}the <Emp>precision</Emp> of your limbs — together, and separately.</Span>,
                ],
                icon: <IconRocket strokeWidth={stroke} size={iconSize} color={color}/>,
                // icon: <IconChartRadar strokeWidth={stroke} size={iconSize} color={color}/>,
                slides: [
                    <AspectRatio ratio={16 / 9} key={++key}>
                        <div style={{width: '100%', height: '100%', maxHeight: 480, position: 'relative'}}>
                            <HookVideo key={++key} ytId={'UKwRo4fYXnQ?si=mhyMV7jsCFTleKru'}
                                       title={'FreeLimbs | Drum coordination made fast-tracked'}
                            />
                        </div>
                    </AspectRatio>
                ]
            },
            {
                title: 'Helping',
                subTitle: 'Traps & pitfalls explained in detail',
                description: [
                    <Span key={++key}>
                        FreeLimbs anticipates your hurdles, <Emp>spotlights the difficult parts</Emp>,
                        {' '}and guides you to help you <Emp>overcome them, with ease</Emp>.
                    </Span>,
                    <Span key={++key}>
                        <Emp>Need for speed</Emp> on that linear chop? FreeLimbs will help you <Emp>build it up</Emp>
                        {' '}by <Emp>visualizing the sticking patterns</Emp> you already know, and by
                        {' '}<Emp>showing you the exact strokes</Emp> and hand motions you can use to <Emp>do it</Emp>.
                    </Span>,
                ],
                icon: <IconHeartHandshake strokeWidth={stroke} size={iconSize} color={color}/>,
                slides: [
                    <AspectRatio ratio={16 / 9} key={++key}>
                        <div style={{width: '100%', height: '100%', maxHeight: 480, position: 'relative'}}>
                            <HookVideo key={++key} ytId={'Qadx5Qam4Go?si=6LpgQtxEsCIHYP3A'}
                                       title={'FreeLimbs | Drum coordination made helpful'}
                            />
                        </div>
                    </AspectRatio>
                ]
            },
            {
                title: 'Handy',
                subTitle: 'Mobile-friendly, practice anywhere',
                description: [
                    <Span key={++key}>FreeLimbs is a web app running on <Emp>any device — desktop, tablet, or
                        smartphone</Emp>,
                        {' '}without the hassle of installation.</Span>,
                    <Span key={++key}>
                        Browsing exercises on your computer or tablet? Just scan the <Emp>exercise&apos;s QR code</Emp> with your
                        smartphone, and
                        dive into practice mode <Emp>wherever you are</Emp>. Also, send it to your drummer friends to
                        challenge them :)
                    </Span>,
                ],
                icon: <IconDeviceMobileHeart strokeWidth={stroke} size={iconSize} color={color}/>,
                slides: []
            },
            /*
                        {
                            title: 'Growing',
                            subTitle: 'We\'re just getting started',
                            description: [
                                <Span key={++key}>A lot is planned or in the making, here&apos;s a glimpse into <Emp>the future of FreeLimbs</Emp>:</Span>,
                                <Span key={++key}><Accent>iOS</Accent> and <Accent>Android</Accent> apps,
                                    {' '}music <Emp>notation</Emp>, <Emp>odd-time</Emp> meters, and more.</Span>,
                            ],
                            icon: <IconGrowth strokeWidth={stroke} size={iconSize} color={color}/>,
                        },
            */
        ];

        return features;

    }, [color]);

    return result;
}
