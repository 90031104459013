import {CoolCard} from "@/components/common/CoolCard";
import {Anchor, Avatar, Blockquote, Group, Stack, Text, Title, Tooltip} from "@mantine/core";
import {
    IconBrandInstagram,
    IconBrandSoundcloud,
    IconBrandTiktok,
    IconBrandX,
    IconBrandYoutube,
    IconQuote
} from "@tabler/icons-react";
import {Testimonial} from "@/components/testimonials/testimonial";
import {parseTextWithSimpleLinks} from "@/components/testimonials/testimonial-utils";

export type TestimonialProps = {
    testimonial: Testimonial;
};

export function TestimonialWidget({
                                      testimonial: {
                                          title,
                                          quote,
                                          name,
                                          avatar,
                                          bio,
                                          more,
                                          tiktok,
                                          twitter,
                                          instagram,
                                          youtube,
                                          soundcloud
                                      }
                                  }: TestimonialProps) {
    return (
        <CoolCard radius={'md'} w={'100%'} h={'100%'} p={'sm'} shadow={'none'} withBorder={false}>
            <Blockquote icon={<IconQuote color={'var(--mantine-color-rougeRed-5)'}/>}
                        color={'transparent'}
                        styles={{
                            root: {
                                backgroundColor: 'transparent'
                            }
                        }}
                        p={'md'}>
                <Stack gap={'lg'}>
                    <Stack gap={0}>
                        <Title order={3} mb={'xs'}>{title}</Title>
                        {quote.map((part, index) => (
                            <Text key={index}>{part}</Text>
                        ))}
                    </Stack>
                    <Group gap={'md'} align={'flex-start'} wrap={'nowrap'}>
                        <Stack gap={'xs'}>
                            <Avatar size={'xl'} radius={'xl'} src={`/images/people/${avatar}`}/>
                            {(instagram || twitter || tiktok || youtube || soundcloud) && (
                                <Group gap={'xs'} align={'center'} wrap={'nowrap'}>
                                    {instagram && (
                                        <Tooltip label={'Instagram'} position={'top'} withArrow>
                                            <Anchor href={`https://www.instagram.com/${instagram}/`} target={'_blank'}>
                                                <IconBrandInstagram size={18}/>
                                            </Anchor>
                                        </Tooltip>
                                    )}
                                    {tiktok && (
                                        <Tooltip label={'TikTok'} position={'top'} withArrow>
                                            <Anchor href={`https://www.tiktok.com/${tiktok}`} target={'_blank'}>
                                                <IconBrandTiktok size={18}/>
                                            </Anchor>
                                        </Tooltip>
                                    )}
                                    {youtube && (
                                        <Tooltip label={'YouTube'} position={'top'} withArrow>
                                            <Anchor href={`https://www.youtube.com/${youtube}`} target={'_blank'}>
                                                <IconBrandYoutube size={18}/>
                                            </Anchor>
                                        </Tooltip>
                                    )}
                                    {twitter && (
                                        <Tooltip label={'X (formerly Twitter)'} position={'top'} withArrow>
                                            <Anchor href={`https://x.com/${twitter}/`} target={'_blank'}>
                                                <IconBrandX size={18}/>
                                            </Anchor>
                                        </Tooltip>
                                    )}
                                    {soundcloud && (
                                        <Tooltip label={'SoundCloud'} position={'top'} withArrow>
                                            <Anchor href={`https://soundcloud.com/${soundcloud}/`} target={'_blank'}>
                                                <IconBrandSoundcloud size={18}/>
                                            </Anchor>
                                        </Tooltip>
                                    )}
                                </Group>
                            )}
                        </Stack>
                        <Stack gap={'xs'}>
                            <Text size={'lg'} fw={700}>{name}</Text>
                            <Text size={'sm'}>
                                {parseTextWithSimpleLinks(bio)}
                            </Text>
                            {/*{more && (*/}
                            {/*    <Text size={'xs'}>*/}
                            {/*        {parseTextWithSimpleLinks(more)}*/}
                            {/*    </Text>*/}
                            {/*)}*/}
                        </Stack>
                    </Group>
                </Stack>
            </Blockquote>
        </CoolCard>
    );
}
