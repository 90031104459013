"use client";

import {MantineColor, MantineFontSize, Stack, StyleProp, Text, Title, TitleOrder} from "@mantine/core";
import {useSlightlyDimmedColor} from "@/components/common/hooks/useColors";
import {CSSProperties, ReactNode} from "react";

export type HeroProps = {
    title: ReactNode;
    titleOrder?: TitleOrder | undefined;
    children: ReactNode | ((props: {
        fontSize: MantineFontSize | number,
        color: StyleProp<MantineColor>
    }) => ReactNode);
    titleSize?: MantineFontSize | number | undefined;
    descriptionSize?: MantineFontSize | number | undefined;
    justified?: boolean | undefined;
    extraBold?: boolean | undefined;
    style?: CSSProperties | undefined;
}

export function HeroComponent(
    {
        title,
        titleOrder,
        children,
        titleSize,
        descriptionSize,
        extraBold,
        style,
    }: HeroProps) {

    const dimmed = useSlightlyDimmedColor();

    const defaultHeroFontSize = 36;
    const defaultTextFontSize = 20;

    const heroSize = titleSize ?? defaultHeroFontSize;
    const textSize = descriptionSize ?? defaultTextFontSize;

    return (
        <Stack gap={'sm'}>
            <Title pt={'md'} order={titleOrder ?? 1}
                   ff={'var(--mantine-font-family-headings)'}
                   lh={1.1}
                   fw={extraBold ? 800 : 700}
                  fz={heroSize}>{title}
            </Title>
            <Title order={2}>
            {typeof children === 'function' && children({fontSize: textSize, color: dimmed})}
            {typeof children !== 'function' && (
                <Text ff={'var(--mantine-font-family)'} py={'xs'} fz={textSize} c={dimmed} style={style}>
                    {children}
                </Text>
            )}
            </Title>
        </Stack>
    )
}
