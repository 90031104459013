"use client";

const EMAIL_REQUIRED = false;

import {Button, Group, Stack, TextInput, Text, Grid, useMantineTheme, CardProps} from "@mantine/core";
import {
    IconCheck,
    IconCreditCard,
    IconExclamationCircle, IconMail
} from "@tabler/icons-react";
import {useDimmedColor, usePrimaryColor, useSlightlyDimmedColor} from "@/components/common/hooks/useColors";
import cx from '@/components/common/MantineCard.module.css';
import {ChangeEvent, ReactNode, useCallback, useEffect, useMemo, useState} from "react";
import {CoolCard} from "@/components/common/CoolCard";
import {useRouter} from "next/navigation";
import {IS_ALPHA, IS_BETA, IS_PAID_IN_PRODUCTION} from "@/common/utils";
import {Span} from "@/components/common/Span";
import {Emp} from "@/components/common/Emp";

export type SignUpCtaProps = {
    descriptionOnSeparateLine?: boolean;
    cta?: string | undefined;
    ctaDescription?: string | undefined;
    children?: ReactNode | undefined;
} & CardProps;

const DefaultCTA = 'Get started';
const DefaultCtaDescription = 'and free your limbs';

export function SignUpCta(
    {
        children,
        cta,
        ctaDescription,
        descriptionOnSeparateLine,
        ...rest
    }: SignUpCtaProps) {

    const dimmed = useSlightlyDimmedColor();
    const veryDimmed = useDimmedColor();

    const primaryColor = usePrimaryColor();
    const theme = useMantineTheme();
    const redColor = theme.colors.rougeRed[5];

    const [email, setEmail] = useState('');
    const [showEmailError, setShowEmailError] = useState(false);

    const router = useRouter();

    const onEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
        setEmail(e.currentTarget.value);
    }

    const emailValidationError = useMemo(() => {
        const errorMessage = 'Email looks invalid, please double-check it.';
        const input = email.trim();
        if (input.length === 0) {
            return null;
        }
        const [user, domain] = input.split('@').map(s => s.trim());
        if (!user) {
            return errorMessage;
        }
        const [domainName, tld] = (domain ?? '').split('.').map(s => s.trim());
        if (!domainName || !tld || tld.length < 2) {
            return errorMessage;
        }
        return null;
    }, [email]);

    useEffect(() => {
        if (email.length > 0 && emailValidationError == null) {
            setShowEmailError(false);
        }
    }, [email.length, emailValidationError]);

    const onSignUp = useCallback(() => {
        if (!EMAIL_REQUIRED) {
            router.push(`/auth/sign-up`);
            return;
        }
        if (emailValidationError != null || email.length === 0) {
            setShowEmailError(true);
            return;
        }
        router.push(`/auth/sign-up?email=${encodeURIComponent(email)}`);
    }, [email, emailValidationError, router]);

    const textSize = 'lg';

    return (
        <CoolCard /*classNames={styles}*/ {...rest}>
            <Grid gutter={{base: 8, md: descriptionOnSeparateLine ? 12 : 16, lg: descriptionOnSeparateLine ? 16 : 24}}>
                {(children != null) && (
                    <Grid.Col span={{base: 12, xs: 12, md: descriptionOnSeparateLine ? 12 : EMAIL_REQUIRED ? 5 : 7}}>
                        <Text pb={4} size={textSize} c={dimmed}>{children}</Text>
                    </Grid.Col>
                )}
                <Grid.Col span={{base: 12, xs: 12, md: (children != null && !descriptionOnSeparateLine) ? EMAIL_REQUIRED ? 7 : 5 : 12}}>
                    <Stack gap={'xs'}>
                        <Grid gutter={{base: 16, md: 24, lg: 32, xl: 40}}>
                            {EMAIL_REQUIRED && (
                                <Grid.Col span={{base: 12, xs: 7, md: 6, lg: 8}}>
                                    <Stack gap={'xs'}>
                                        <TextInput
                                            classNames={{
                                                input: cx.root
                                            }}
                                            size={'md'}
                                            value={email}
                                            type={'email'}
                                            autoComplete={'on'}
                                            leftSection={<IconMail size={18}/>}
                                            placeholder={'Enter your email'}
                                            onChange={onEmailChange}
                                            rightSection={
                                                (email.length === 0) ? (
                                                        /*
                                                                                                            <Tooltip label={'Email is required'} openDelay={350} closeDelay={100}>
                                                                                                                <IconAsterisk color={redColor} size={16}/>
                                                                                                            </Tooltip>
                                                        */
                                                        null
                                                    ) :
                                                    emailValidationError ? (
                                                        <IconExclamationCircle color={redColor} size={16}/>
                                                    ) : (
                                                        <IconCheck color={primaryColor} size={16}/>
                                                    )
                                            }
                                        />
                                        {(!showEmailError && (emailValidationError == null || email.length === 0)) ? null : (
                                            <Text size={'sm'}
                                                  c={redColor}>{emailValidationError ?? 'Email is required'}</Text>
                                        )}
                                    </Stack>
                                </Grid.Col>
                            )}
                            <Grid.Col>
                                <Button variant={'filled'}
                                        fullWidth
                                        size={'lg'}
                                        // rightSection={<IconArrowRight size={18}/>}
                                        onClick={onSignUp}
                                >
                                    <Stack gap={0} my={'md'} align={'center'}>
                                        <Text inherit>{cta ?? DefaultCTA}</Text>
                                        <Text fz={'xs'} fw={'normal'}>{ctaDescription ?? DefaultCtaDescription}</Text>
                                    </Stack>
                                </Button>
                            </Grid.Col>
                        </Grid>
                        {!IS_PAID_IN_PRODUCTION && (
                            <Span>
                                {' '}Sign up now and be one of the few to get <Emp>free access</Emp>
                                {' '}during the {IS_ALPHA ? 'Alpha ' : IS_BETA ? 'Beta ' : ''}testing period.
                            </Span>
                        )}
                        {IS_PAID_IN_PRODUCTION && (
                            <Stack gap={6} pt={4}>
                                <Span fz={'sm'}>
                                    {' '}Sign up now and enjoy your <Emp>free</Emp> 14-day trial with <Emp>access to
                                    all content, features & tracks</Emp>.
                                </Span>
                                <Group align={'center'} gap={'xs'}>
                                    <Group align={'center'} gap={'xs'} wrap={'nowrap'}>
                                        <IconCreditCard size={18} color={veryDimmed}/>
                                        <Text size={'sm'} c={veryDimmed}>
                                            No payment details required
                                        </Text>
                                    </Group>
                                </Group>
                            </Stack>
                        )}
                    </Stack>
                </Grid.Col>
            </Grid>
        </CoolCard>
    )
}
