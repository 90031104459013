import {useAtomValue} from "jotai/index";
import {store} from "@/app/lib/data/appState";
import {useMemo} from "react";
import {createClient} from "@/utils/supabase/client";

export function useLoggedInUser() {
    const loadingUser = useAtomValue(store.loadingUser);
    const user = useAtomValue(store.user);

    const supabase = useMemo(() => createClient(), []);

    return {loadingUser, user, authClient: supabase};
}
