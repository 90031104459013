import {em, useMantineTheme} from "@mantine/core";
import {useMediaQuery} from "@mantine/hooks";
import {useEffect, useState} from "react";
import { useOrientation } from "@uidotdev/usehooks";

export const DEBUG_BREAKPOINTS = false;

const MIN_DESKTOP_HEIGHT = 600;
const MIN_DESKTOP_WIDTH = 600;
const SHORT_SCREEN_HEIGHT = 700;

export type BpWidthXxs = 0;
export type BpWidthXs = 1;
export type BpWidthSm = 2;
export type BpWidthMd = 3;
export type BpWidthLg = 4;
export type BpWidthXl = 5;
export type BpWidthXxl = 6;
export type BpWidth3xl = 7;
export type BpWidthUltra = 8;

export const BwXxs: BpWidthXxs = 0;
export const BwXs: BpWidthXs = 1;
export const BwSm: BpWidthSm = 2;
export const BwMd: BpWidthMd = 3;
export const BwLg: BpWidthLg = 4;
export const BwXl: BpWidthXl = 5;
export const BwXxl: BpWidthXxl = 6;
export const Bw3xl: BpWidth3xl = 7;
export const BwUltra: BpWidthUltra = 8;

export type BreakpointWidth = BpWidthXxs | BpWidthXs | BpWidthSm | BpWidthMd |
    BpWidthLg | BpWidthXl | BpWidthXxl | BpWidth3xl | BpWidthUltra;

export const BreakpointWidthNames = [
    'xxs',
    'xs',
    'sm',
    'md',
    'lg',
    'xl',
    'xxl',
    '3xl',
    'ultra'
];

export function getConditionalPadding(
    notes: number,
    staffLines: number,
    measures: number,
    availWidth: number,
    availHeight: number
): number {
    const columns = notes + 1; // for the instrument
    const rows = staffLines + 1; // for the count syllables

    const scoreWidthToHeightRatio = ((measures * columns) / rows);

    const targetWidth = scoreWidthToHeightRatio * availHeight;
    const remainingWidth = Math.max(0, availWidth - targetWidth);
    const padding: number = Math.floor(remainingWidth / 2);

    // console.log('s2w', scoreWidthToHeightRatio, 'avail', availWidth, 'target', targetWidth, 'rem', remainingWidth, 'p', padding);

    return padding;
}

export function useBreakpointWidth(): BreakpointWidth {
    const theme = useMantineTheme();
    const isXxs = useMediaQuery(`(max-width: ${em(400)})`, undefined, {getInitialValueInEffect: true});
    const isXs = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`, undefined, {getInitialValueInEffect: true});
    const isSm = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`, undefined, {getInitialValueInEffect: true});
    const isMd = useMediaQuery(`(max-width: ${theme.breakpoints.md})`, undefined, {getInitialValueInEffect: true});
    const isLg = useMediaQuery(`(max-width: ${theme.breakpoints.lg})`, undefined, {getInitialValueInEffect: true});
    const isXl = useMediaQuery(`(max-width: ${theme.breakpoints.xl})`, undefined, {getInitialValueInEffect: true});
    const isXxl = useMediaQuery(`(max-width: ${em(1476)})`, undefined, {getInitialValueInEffect: true});
    const is3xl = useMediaQuery(`(max-width: ${em(1728)})`, undefined, {getInitialValueInEffect: true});
    const isUltra = useMediaQuery(`(min-width: ${em(1729)})`, undefined, {getInitialValueInEffect: true});
    if (isXxs) return BwXxs;
    if (isXs) return BwXs;
    if (isSm) return BwSm;
    if (isMd) return BwMd;
    if (isLg) return BwLg;
    if (isXl) return BwXl;
    if (isXxl) return BwXxl;
    if (is3xl) return Bw3xl;
    if (isUltra) return BwUltra;
    return BwUltra;
}

export const useWindowSize = () => {
    const [windowSize, setWindowSize] = useState<{
        width?: number;
        height?: number;
    }>({
        width: undefined,
        height: undefined
    });

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight
            });
        }

        window.addEventListener("resize", handleResize);

        // Call handler right away so state gets updated with initial window size
        handleResize();

        // Don't forget to remove event listener on cleanup
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowSize;
};

function isSpeculativeMobile(): boolean {
    if (typeof window === 'undefined' || typeof window.navigator === 'undefined') {
        return false;
    }
    if ('userAgentData' in window.navigator &&
        // @ts-ignore
        'mobile' in window.navigator.userAgentData &&
        // @ts-ignore
        window.navigator.userAgentData.mobile === true) {
        return true;
    }
    if (('ontouchstart' in window || window.navigator.maxTouchPoints > 0) &&
        // removed 'iPad|' from the regex - it's not a smartphone
        /Mobi|Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent)) {
        return true;
    }
    if ('matchMedia' in window &&
        window.matchMedia(`only screen and (max-width: ${MIN_DESKTOP_WIDTH}px)`).matches) {
        return true;
    }
    return false;
}

export function isSafari(): boolean {
    if (typeof window === 'undefined' || typeof window.navigator === 'undefined') {
        return false;
    }
    return /Safari/i.test(navigator.userAgent) &&
        !/Chrome/i.test(navigator.userAgent);
}

export function isIphoneOrSafari(): boolean {
    if (typeof window === 'undefined' || typeof window.navigator === 'undefined') {
        return false;
    }
    return /iPhone|iPod/i.test(window.navigator.userAgent) &&
        /Safari/i.test(window.navigator.userAgent) &&
        !/Chrome/i.test(navigator.userAgent);
}

export function useMobile(): boolean {
    const size = useWindowSize();
    if (isSpeculativeMobile()) {
        return true;
    }
    if (size.width == null || size.height == null) {
        return false;
    }
    return (size.height < MIN_DESKTOP_HEIGHT || size.width < MIN_DESKTOP_WIDTH);
}

export function useTiltedMobile() {
    const isMobile = useMobile();
    const size = useWindowSize();
    const orientation = useOrientation();
    if (!isMobile) {
        return false;
    }
    if (orientation?.type != null) {
        return orientation.type.startsWith('landscape');
    }
    if (size.width == null || size.height == null) {
        return false;
    }
    return size.height < MIN_DESKTOP_HEIGHT && size.width > size.height;
}

export function useShortScreen(): boolean {
    const size = useWindowSize();
    if (size.width == null || size.height == null) {
        return false;
    }
    return size.height <= SHORT_SCREEN_HEIGHT;
}

/* Also use if necessary:

import MobileDetect from 'mobile-detect';

const md = new MobileDetect(navigator.userAgent);
if (md.mobile()) {
  console.log("Mobile device detected");
} else {
  console.log("Desktop device detected");
}

OR

import Bowser from 'bowser';

const parser = Bowser.getParser(navigator.userAgent);
if (parser.getPlatformType() === 'mobile') {
  console.log("Mobile device detected");
} else {
  console.log("Desktop device detected");
}

OR


import platform from 'platform';

if (platform.isMobile) {
  console.log("Mobile device detected");
} else {
  console.log("Desktop device detected");
}
 */