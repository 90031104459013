"use client";

import {usePathname} from "next/navigation";

export function useSafePathName() {
    const pathName = usePathname();
    if (pathName != null) {
        return pathName;
    }
    if (typeof window === "undefined") {
        return "";
    }
    if (window.location == null) {
        return "";
    }
    return window.location.pathname;
}
