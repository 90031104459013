import {selectAtom} from "jotai/utils";
import {atomWithImmer} from "jotai-immer";
import {UserProfile} from "@/app/lib/profile/user-profile";
import {User} from "@supabase/supabase-js";

export type AppState = {
    customAffiliateId: string | null;
    toltAffiliateId: string | null;
    loadingUser: boolean;
    user: User | undefined;
    loadingProfile: boolean;
    profile: UserProfile | undefined;
};

export const appState = atomWithImmer<AppState>(
    {
        customAffiliateId: null,
        toltAffiliateId: null,
        loadingUser: true, // NB: this MUST be true, or we'll exit the loading state too early throughout the app
        user: undefined,
        loadingProfile: true, // NB: this MUST be true, or we'll exit the loading state too early throughout the app
        profile: undefined,
    }
);

export const customAffiliateId = selectAtom(
    appState, s => s.customAffiliateId);

export const toltAffiliateId = selectAtom(
    appState, s => s.toltAffiliateId);

const loadingUser = selectAtom(
    appState, s => s.loadingUser);

const user = selectAtom(
    appState, s => s.user);

const loadingProfile = selectAtom(
    appState, s => s.loadingProfile);

const profile = selectAtom(
    appState, s => s.profile);

export const store = {
    state: appState,
    customAffiliateId,
    toltAffiliateId,
    loadingUser,
    user,
    loadingProfile,
    profile
};
