"use client";

import {Grid} from "@mantine/core";
import {PlainContentSection} from "@/components/common/PlainContentSection";

export function Facility({content}: { content: string }) {
    return (
        <Grid gutter={0}>

            <Grid.Col span={{base: 12}}
                      // hiddenFrom={'lg'}
            >
                <PlainContentSection py={'md'} maw={'100%'} mih={'100%'} defaultFontSize={'lg'} withBorder>
                    {content}
                </PlainContentSection>
            </Grid.Col>

{/*
            <Grid.Col span={{base: 12}} visibleFrom={'lg'}>
                <CoolCard mih={'100%'} px={0} py={'xs'} withBorder={true}>
                    <Grid gutter={{base: 0}}>
                        <Grid.Col span={{base: 12, md: 7}} visibleFrom={'lg'}>
                            <PlainContentSection noBg maw={'100%'} mih={'100%'} defaultFontSize={'lg'}>
                                {content}
                            </PlainContentSection>
                        </Grid.Col>
                        <Grid.Col span={{base: 12, md: 5}} visibleFrom={'lg'}>
                            <Stack gap={0} px={'md'} mih={'100%'} pt={'md'}>
                                <AspectRatio ratio={1}>
                                    <Image src={'/images/illustrations/effortless-drumming-2.jpeg'}
                                           alt={'Effortless drumming illustration'}
                                           layout={'fill'}
                                           objectFit={'cover'}
                                    />
                                </AspectRatio>
                            </Stack>
                        </Grid.Col>
                    </Grid>
                </CoolCard>

            </Grid.Col>
*/}

        </Grid>);
}
