import {Card, CardProps} from "@mantine/core";
import cx from "@/components/common/MantineCard.module.css";

export function CoolCard(props: CardProps & { noBg?: boolean | undefined }) {
    const {noBg, children, ...rest} = props;
    return (
        <Card classNames={noBg ? undefined : cx}
              style={{backgroundColor: noBg ? 'transparent' : undefined}}
              withBorder={true}
              shadow={noBg ? 'none' : 'md'}
              {...rest}>
            {children}
        </Card>
    );
}
