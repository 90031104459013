"use client";

import {MarkdownContent} from "@/components/common/MarkdownContent";
import {CoolCard} from "@/components/common/CoolCard";
import {MantineFontSize, MantineSize} from "@mantine/core";
import {MarkdownPreviewProps} from "@/components/common/MarkdownPreview";

export type PlainContentSectionProps = {
    children: React.ReactNode,
    defaultFontSize?: MantineSize | MantineFontSize | number | undefined;
    noBg?: boolean | undefined;
    withBorder?: boolean;
    horPadding?: MantineSize | number | undefined;
} & MarkdownPreviewProps;

export function PlainContentSection({children, defaultFontSize, withBorder, noBg, horPadding, ...rest}: PlainContentSectionProps) {
    return (
        <CoolCard noBg={noBg} py={0} px={horPadding ?? 'lg'} withBorder={withBorder} mih={'100%'}>
            <MarkdownContent content={String(children)}
                             align={'justify'}
                             defaultFontSize={defaultFontSize}
                             paragraphSpacing={8}
                             {...rest}
            />
        </CoolCard>
    );
}
