import {Grid} from "@mantine/core";
import {Feature} from "@/components/features/Feature";
import {FeatureItem} from "@/components/features/FeatureItem";

export type FeaturesProps = {
    features: Feature[];
};

export function Features({ features } : FeaturesProps) {
    return (
        <Grid gutter={{ base: 12, sm: 16, md: 24, lg: 32 }}>
            {features.map((feature, i) => (
                <Grid.Col span={{base: 12}} key={i}>
                    <FeatureItem feature={feature} odd={
                        (i % 2) === 0
                    }/>
                </Grid.Col>
            ))}
        </Grid>
    );
}
