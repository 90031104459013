import {HeroComponent} from "@/components/common/HeroComponent";
import {Accent} from "@/components/common/Accent";
import {Span} from "@/components/common/Span";
import {Text} from "@mantine/core";
import {Emp} from "@/components/common/Emp";

export function RealTimeHero() {
    return (
        <HeroComponent titleOrder={2} title={<Span>Improve <u>faster</u></Span>}
            // extraBold
                       titleSize={32}
                       descriptionSize={20}>
            {({fontSize, color}) => (
                <Text ff={'var(--mantine-font-family)'} py={'xs'} fz={fontSize} c={color}>
                    {' '}Connect your <Accent>electronic drums</Accent> and get honest <Emp>real-time feedback</Emp>
                    {' '}on your performance.
                    {' '}<Emp><u>Know</u></Emp> if you&apos;re playing <Accent>in the pocket</Accent>, <Accent>grooving</Accent>,
                    {' '}<Accent>rushing</Accent> or <Accent>dragging</Accent>.
                </Text>
            )}
        </HeroComponent>
    );
}
