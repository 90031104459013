import {HeroComponent} from "@/components/common/HeroComponent";
import {Accent} from "@/components/common/Accent";
import {Text} from "@mantine/core";
import {Emp} from "@/components/common/Emp";

export function Hero() {
    return (
        <HeroComponent title={'Master drum set coordination'}
                       extraBold
                       titleSize={38}
                       descriptionSize={24}>
            {({fontSize, color}) => (
                <Text ff={'var(--mantine-font-family)'} py={'xs'} fz={fontSize} c={color}>
                    <Emp>Free your hands and feet</Emp>, and
                    {' '}gain <Accent>4-way limb independence</Accent> on the drums.
                    {' '}Practice countless <Accent>grooves</Accent> and <Accent>linear fills</Accent>
                    {' '}in <Accent>all genres</Accent>, while jamming to <Accent>300+ drumless tracks</Accent>.
                </Text>
            )}
        </HeroComponent>
    );
}
