"use client";

import {MarkdownPreview, MarkdownPreviewProps} from "@/components/common/MarkdownPreview";
import {Box} from "@mantine/core";
import {useShortScreen} from "@/components/common/hooks/responsive-utils";

export function ClientMarkdownContent(props: MarkdownPreviewProps) {
    const isShort = useShortScreen();
    return (
        <Box maw={props.maw ?? (isShort ? 800 : 1024)} style={{ overflowY: 'auto' }}>
            <MarkdownPreview {...props} />
        </Box>
    );
}
