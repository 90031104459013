import { createBrowserClient } from '@supabase/ssr'
import {SupabaseCookieOptions} from "@/common/utils";

export function createClient() {
    return createBrowserClient(
        process.env.NEXT_PUBLIC_SUPABASE_URL!,
        process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY!,
        {
            cookies: {},
            cookieOptions: SupabaseCookieOptions,
            isSingleton: true
        }
    )
}